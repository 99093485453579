


























































import { Component, Vue } from 'vue-property-decorator';
import { appointmentModule } from '@/store/modules/appointment';
@Component
export default class ConfirmAppointment extends Vue {
    show = false;
    comment = '';
    step = 1;

    async store() {
        try {
            await appointmentModule.confirmAppointment({
                idCita: this.$route.params.id,
                estado: 2,
                comentario: this.comment
            });
            this.step++;
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }
}
